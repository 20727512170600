import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions as authActions } from 'features/Auth';

import settings from './img/settings.svg';
import logout from './img/logout.svg';

import './Footer.scss';

const Footer = ({ locale, lang, changeLang, logOut, isAuth }) => {
  const b = block('footer');
  const dispatch = useDispatch();
  const role = useSelector(state => state.auth.role, shallowEqual);

  return (
    <footer className={b()}>
      <div className={b('title')}>{locale.other}</div>
      <Link to="/locale" className={b('item')}>
        {locale.changeLang}
        <SVGInline svg={settings} className={b('image').toString()} />
      </Link>
      {isAuth && (
        <Link to="/change-pas" className={b('item')}>
          {locale.changePas}
          {/* <SVGInline svg={settings} className={b('image').toString()} /> */}
        </Link>
      )}
      {isAuth && (
        <div className={b('item')} onClick={() => dispatch(authActions.logOut())}>
          {locale.logout}
          <SVGInline svg={logout} className={b('image').toString()} />
        </div>
      )}

      {role === 3 && (
        <div className={b('alert-message')}>
          <div className={b('social')}>
            {locale.social}
            <br />
            <a href="https://wa.me/79504513534" className={b('whatsapp')} target="_blank" rel="noopener noreferrer">
              WhatsApp - https://wa.me/79504513534
            </a>
            <br />
            <a
              href="https://t.me/huntingseasonisnowopen"
              className={b('telegram')}
              target="_blank"
              rel="noopener noreferrer">
              Telegram - https://t.me/huntingseasonisnowopen
            </a>
          </div>

          <div className={b('alert')}>{locale.alert}</div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
