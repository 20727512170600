import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Changer langue',
  changePas: 'Changer pasword',
  logout: 'Deconnecter',
  other: 'Other',

  newPlayer: 'Nouveau client',
  userName: 'Nom de utilisateur',
  password: 'Mot de passe',
  createAccount: 'Creer un compte',
  '/user-create': 'Cash register - New player',
  '/total': 'Cash register - comptabilitee',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/change-pas': 'Changer pasword',
  '/user-edit': 'Cash register - User edit',

  '/users-list': 'Cash register - balance des clients',
  userCardBalances: 'Balances des clients',
  userCard: 'Clients',
  accountBalance: 'Balance de compte',
  nickname: 'Nom de utilisateur',
  id: 'Identifiant',
  balance: 'Balance',
  ban: 'Bloquer',
  banSuccess: 'Bloquer success',
  unbanSuccess: 'Debloquer success',
  unban: 'Debloquer',
  resetPassword: 'Réinitialiser',
  resetSuccess: 'Mot de passe réinitialisee',
  networkError: 'Erreur de reseau',
  createSuccess: 'Succès de creation',
  topUp: 'Depot',
  withdrawal: 'Retrait',
  topUpSuccess: 'Succès  de depot',
  withdrawalSuccess: 'Succès  de retrait',
  topUpWithdrawal: 'Depot/Retrait',
  ok: 'Ok',
  theNewPassword: 'Nouveau mot de passe ',
  for: 'pour',

  '/transaction-history': 'clients - Historique de transactions',
  startDate: 'Date de debut',
  endingDate: 'fin',
  display: 'Representer',
  email: 'Nom de utilisateur',
  date: 'Date',
  amount: 'montant',

  languageSelection: 'selectionner langues',
  login: 'Me Connecter',

  cashRegister: 'Cash register',
  partners: 'clients',
  createUser: {
    1: 'New user',
    2: 'New cashier',
    3: 'New admin',
    7: 'New superadmin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
    7: 'Superadmins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
    7: 'Superadmins transactions',
  },
  transactionHistory: 'Transaction history',
  revenuBalances: 'Revenu balances',

  total: 'Totale',
  term: 'Terme',
  cashPayment: 'Payement en espece',
  cashPayout: 'Deboursement en espece',
  withdraw: 'Retrait',
  deposit: 'Depot',
  search: 'Search',
  errorPassword:
    'Le mot de passe doit comprendre: au moins 8 caractères, au moins un chiffre, une majuscule et une minuscule!',
  noPass: 'Le mot de passe ne doit pas être vide!',

  passwordNotHasSY: 'Le mot de passe doit contenir un caractère spécial',
  passwordNotHasCL: 'Le mot de passe doit contenir une lettre majuscule',
  passwordNotHasNumber: 'Le mot de passe doit contenir un nombre',
  passwordTooShort: 'Le mot de passe doit contenir plus de 8 caractères',
  passwordNotHasLetter: 'Le mot de passe doit contenir une lettre',

  social: `Official X10tn contacts:`,
  alert: 'TOUS LES AUTRES CONTACTS SONT DES ARNAQUES. SEUL NOTRE CONTACT PEUT VENDRE LE SOLDE! NE CRAQUEZ PAS POUR LES RÉDUCTIONS!',

  players: 'Joueurs',
  subusers: 'Sous-utilisateurs',
  tree: 'Tree',
  mysteryBalance: `Mystère 
équilibre`,
  mysteryBalanceInfo: `Il s'agit d'un solde mystique qui sera distribué tous les <span style="color: #FFB401;">3 jours</span> ! Enregistrez plus de joueurs et obtenez plus d'équilibre mystique avec lequel vous pouvez recharger les soldes des joueurs. Après le dépôt, les joueurs devront miser le montant du solde mystère, 1 pour 1 dans les sports et 1 pour 2 dans les jeux, c'est-à-dire si vous déposez <span style="color: #FFB401;">100 TND</span> de solde mystère, alors pour retirer de l'argent le joueur devra parier <span style="color: #FFB401;">100 TND</span> dans le sport ou <span style="color: #FFB401;">200 TND</ span> dans les jeux.`,
};
